import { navigate } from "gatsby"
import { Button, Form, TextInput} from 'carbon-components-react';
import React, {useState} from 'react';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import validateEmail from '../util/validateEmail'
import {InlineNotification} from 'gatsby-theme-carbon';

import {
  siteMetadata
} from "../../gatsby-config"

async function jsonRequest(endpoint, method, payload){
  const url = `${siteMetadata.drupalUrl}/${endpoint}`;
  const init = {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
  }
  return fetch(url,init);
}

const RegistryForm = ({pageContext, location}) => {
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [newPass, setNewPass] = useState("")
  const [confirmedPass, setConfirmedPass] = useState("")
  const [message, setMessage] = useState(null)
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false)

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    switch(name) {
      case 'field_full_name':
        setFullName(value)
        break
      case 'email':
        setEmail(value)
        break
      case 'newPass':
        setNewPass(value)
        break
      case 'confirmedPass':
        setConfirmedPass(value)
        break
      default:
        console.log('field has no state')
    }
  }


  const handleSubmit = async event => {
    event.preventDefault();
    event.persist();
    if(!loading){
      setLoading(true);
      setMessage(null);
      if (newPass !== confirmedPass) {
        setMessage("Passwords do not match");
      } else {
        try {
          // const valid = await recaptchaSubmit(executeRecaptcha)
          if(fullName && fullName.length > 0 && email && validateEmail(email)){
            const payload = {
              field_full_name: [{value: fullName}],
              name: [{value: email}],
              mail: [{value: email}],
              pass: [{value: newPass}],
            };
            const response = await jsonRequest("user/register?_format=json", "POST", payload);
            if(response.ok){
              navigate('/login', {state: {msg: "Registration successful, you can now login."}})
            }else{
              throw new Error("Unable to create account.")
            }
          }else{
            throw new Error("Please check all the fields and try again")
          }
        } catch (err) {
          setLoading(false);
          setMessage(err.message);
        }
      }
    }
  }

  return (
    <div style={{maxWidth: `620px`}}>
      <div>
        <p>Fill out the form below to create an account</p>
      </div>
      <Form className="register-form" onSubmit={handleSubmit}>
        {!!message && <InlineNotification kind="error"><p>{message}</p></InlineNotification>}
        <TextInput
          light
          required
          type="text"
          name="field_full_name"
          id="field_full_name"
          value={fullName}
          onChange={handleInputChange}
          disabled={loading}
          labelText="Full Name"
        />
        <TextInput 
          light
          required
          labelText="Email"
          type="email"
          name="email"
          id="email"
          value={email}
          onChange={handleInputChange}
          disabled={loading}
        />
        <TextInput 
          light
          required
          labelText="Password"
          type="password"
          name="newPass"
          id="newPass"
          value={newPass}
          onChange={handleInputChange}
          disabled={loading}
        />
        <TextInput 
          light
          required
          labelText="Confirm Password"
          type="password"
          name="confirmedPass"
          id="confirmedPass"
          value={confirmedPass}
          onChange={handleInputChange}
          disabled={loading}
        />
        <Button type="submit" disabled={loading}>Submit</Button>
      </Form>
    </div>
  )
}

const RegisterPage = ({pageContext, location}) => {
  pageContext = {
    ...pageContext,
    frontmatter: { title: "NSSSP Application", tabs: ["Profile", "Application"] },
  };
  return (
    <RegistryForm
      pageContext={pageContext}
      location={location}
    />
  );
}

export default RegisterPage
